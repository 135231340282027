@import "~@theme/variables.scss";
.accountDialog {
  width: calc(100% - 30px);
  max-width: 830px;
  margin: 60px 15px 15px 15px;
  border-radius: 0;
  color: $slate;
  font-family: $akkurat;
  overflow-y: visible;

  @media (min-width: #{$lg}) {
    margin: 30px 0;
  }
}

.accountDialogClean {
  width: calc(100% - 30px);
  max-width: 494px;
  margin: 30px 0;
  padding: 30px 0 0;
  border-radius: 0;
  color: $slate;
  font-family: $akkurat;
  overflow-y: visible;

  @media (min-width: #{$lg}) {
    padding: 72px 56px;
  }
}

.accountDialogHeader {
  position: relative;
  background-color: $lightGrayDark;
  padding: 9px 30px;

  @media (min-width: #{$lg}) {
    padding: 17px 50px;
  }
}

.accountDialogHeaderClose {
  position: absolute;
  right: 0;
  top: -50px;
  width: 35px;
  height: 35px;
  background-color: $lightestGray;
  color: $darkGray;

  :global {
    .icon {
      font-size: 16px;
    }
  }

  &:hover {
    background-color: $slate;
    color: $white;
  }

  @media (min-width: #{$lg}) {
    right: 10px;
    top: 20px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
}

.accountDialogTitle {
  font-size: 22px;
  line-height: 1.3182;
  letter-spacing: -0.02em;

  @media (min-width: #{$lg}) {
    font-size: 32px;
    line-height: 1.3125;
  }
}

.accountDialogContentSubTitle {
  font-size: 24px;
  line-height: 1.29167;
  margin-bottom: 24px;
}

.accountDialogContentSubTitleClean {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 12px 0;
}

.accountDialogContentClean {
  padding: 0;
}
