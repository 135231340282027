@import "~@theme/variables.scss";
.PageTitle {
  font-weight: 300;
  font-size: 36px;
  line-height: 1.0834;
  letter-spacing: -0.04em;
  margin-bottom: 30px;

  @media screen and (min-width: $lg) {
    font-size: 56px;
    margin-bottom: 41px;
    line-height: 1.0715;
  }
}

.PageTitleLargeTP {
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 116%;

  @media screen and (min-width: $lg) {
    font-size: 56px;
    margin-bottom: 41px;
  }
}

.PageTitleCheckout {
  font-weight: 400;
  font-size: 36px;
  line-height: 1.2;
  letter-spacing: -5%;
  margin-bottom: 48px;
  text-align: center;

  @media screen and (min-width: $lg) {
    text-align: left;
  }
}

.PageTitleLargeMedium {
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.05em;
  font-weight: 400;

  @media screen and (min-width: $lg) {
    font-size: 48px;
    line-height: 120%;
    margin-bottom: 48px;
  }
}

.PageTitleMedium {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.3125;
  letter-spacing: -0.02em;
  margin-top: 5px;
  margin-bottom: 20px;

  @media (min-width: #{$lg}) {
    font-size: 32px;
    margin-top: 15px;
  }
}

.PageTitleMediumLarge {
  font-size: 32px;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;

  @media (min-width: #{$lg}) {
    font-size: 24px;
    margin-top: 57px;
    margin-bottom: 17px;
  }
}

.PageTitleSmall {
  font-size: 18px;
  line-height: 1.61112;
  margin-bottom: 24px;
  font-weight: 400;
  letter-spacing: 0;

  @media (min-width: #{$lg}) {
    font-size: 24px;
    line-height: 1.29167;
    margin-bottom: 48px;
  }
}

.PageTitleSmallMedium {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.29167;
  margin-bottom: 16px;
  letter-spacing: 0;
}

.PageTitleCenter {
  text-align: center;
}

.PageTitleHasSubTitle {
  margin-bottom: 10px;

  @media screen and (min-width: $lg) {
    margin-bottom: 18px;
  }
}

.hideDesktop {
  display: block;
  @media (min-width: #{$lg}) {
    display: none;
  }
}

.hideMobile {
  display: none;

  @media (min-width: #{$lg}) {
    display: block;
  }
}
