@import "~@theme/variables.scss";
.newHeaderUserMenu {
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin-bottom: 40px;

  @media (min-width: $lg) {
    border-radius: 10px;
    margin-bottom: 0;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: 22px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent;
      border-bottom-color: $slate;
    }
  }
}

.newHeaderUserMenuTop {
  background: $slate;
  color: $white;
  padding: 24px;

  & a {
    color: $white;
    text-decoration: underline;
  }
}

.newHeaderUserMenuUsername {
  font-size: 24px;
  line-height: 1.29167;
  margin-bottom: 16px;

  :global {
    .icon {
      font-size: 16px;
    }
  }
}

.newHeaderUserMenuInfo {
  font-size: 14px;
  line-height: 1.57143;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & b {
    display: inline-block;
    text-transform: uppercase;
    min-width: 190px;
    padding-right: 8px;
  }
}

.newHeaderUserMenuBottom {
  background: $white;
  padding: 24px;
}

.newHeaderUserMenuItems {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: $lg) {
    display: block;
  }
}

.newHeaderUserMenuItem {
  position: relative;
  padding: 0 0 0 32px;
  display: block;
  margin-bottom: 24px;
  min-height: 0;
  font-size: 16px;
  line-height: 1.5625;
  color: $slate;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: none;
  }

  :global {
    .icon {
      font-size: 16px;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }

  @media (min-width: $lg) {
    margin-bottom: 10px;
  }
}
