@import "~@theme/variables.scss";
.accountDialogActions {
  display: block;
  text-align: center;
  padding: 0 30px 30px;

  :global {
    .note {
      margin-top: 24px;
    }
  }

  button {
    width: 100%;
  }

  button + button {
    margin-top: 16px;
  }

  @media (min-width: #{$lg}) {
    padding: 0 50px 50px;

    button {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      min-width: 280px;
      display: flex;
    }
  }
}

.actionsSpacingTopBottom {
  padding: 0 0 30px !important;

  @media (min-width: #{$lg}) {
    padding: 50px 0 !important;
  }
}

.accountDialogActionsRow {
  @media (min-width: #{$lg}) {
    display: flex;
    justify-content: flex-start;

    button {
      min-width: 0;
      margin-left: 0;
      margin-right: 20px;
    }

    button + button {
      margin-top: 0;
    }
  }
}

.actionsAlignRight {
  @media (min-width: #{$lg}) {
    justify-content: flex-end;

    button {
      min-width: 0;
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

.accountDialogEditHHMActions {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 350px;

  button {
    width: auto;
    min-width: 0;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    margin: 0;
  }

  button + button {
    margin-left: 20px;
    margin-top: 0;
  }
}
