@import "~@theme/variables.scss";
.helpButtonFooter {
  font-size: 0;
  line-height: 0;
  color: transparent;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0;
  min-width: 0;
  padding: 2px;
  background-color: rgba($white, 0.6);
  border-radius: 2px;
  position: fixed;
  z-index: 100;
  bottom: 13px;
  right: 13px;

  @media (min-width: #{$lg}) {
    font-size: 18px;
    line-height: 1.61112;
    padding: 4px;
    color: $darkestGray;
    bottom: 6px;
    right: 46px;
  }

  @media print {
    display: none;
  }

  &:hover {
    background-color: rgba($white, 0.6);
  }

  :global {
    .icon {
      font-size: 24px;
      color: $darkestGray;
      margin: 0;

      @media (min-width: #{$lg}) {
        margin-right: 5px;
      }
    }
  }
}

.helpButton {
  font-size: 16px;
  padding: 12px 32px;

  :global {
    .icon {
      margin-right: 8px;
      font-size: 16px;
      max-width: 1em;
      max-height: 1em;
    }
  }
}
