@import "~@theme/variables.scss";
.accountDialogContent {
  padding: 30px;

  @media (min-width: #{$lg}) {
    padding: 50px;
  }

  :global {
    .text-center {
      text-align: center;
    }

    .dialog_actions {
      padding: 30px 0 0;

      @media (min-width: #{$lg}) {
        padding: 50px 0 0;
      }
    }
  }
}

.accountDialogContentClean {
  padding: 0 30px;

  @media (min-width: #{$lg}) {
    padding: 0;
  }
}
