@import "~@theme/variables.scss";
.PaymentCompleteInformationBox {
  border-width: 1px;
  border-style: solid;
  border-color: $border;
  padding: 15px;
  margin-bottom: 30px;

  @media (min-width: #{$lg}) {
    padding: 25px;
    margin-bottom: 60px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
}

.roundedSize-small {
  border-radius: 4px;
}

.roundedSize-medium {
  border-radius: 8px;
}

.roundedSpacings-small {
  padding: 32px;
  margin-top: 24px;
  margin-bottom: 0;
}
