@import "~@theme/variables.scss";
.TextBlock {
  ul {
    margin: 0;
    padding-left: 25px;
  }

  :global {
    .text-light {
      font-weight: 300;
    }
  }
}

.TextBlockMedium {
  font-size: inherit;
}

.TextBlockMediumSmall {
  font-size: 14px;
  line-height: 155%;
}

.TextBlockMediumLarge {
  font-size: 14px;
  line-height: 160%;

  @media (min-width: #{$lg}) {
    font-size: 18px;
    line-height: 28px;
  }

  p {
    @media (min-width: #{$lg}) {
      margin-bottom: 1.4em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.TextBlockSmall {
  font-size: 12px;
}

.TextBlockSmaller {
  font-size: 10px;
}

.TextBlockGray {
  color: $gray;
}

.TextBlockSlate {
  color: $slate;
}

.TextBlockMediumDarkGray {
  color: $mediumDarkGray;
}

.TextBlockDarkGray {
  color: $darkGray;
}

.TextBlockDarkestGray {
  color: $darkestGray;
}

.TextBlockBottom {
  margin-bottom: 10px;
}

.TextBlockParagraphBottomLarge {
  p {
    @media (min-width: #{$lg}) {
      margin-bottom: 32px;
    }
  }
}

.TextBlock14lg12sm {
  font-size: 12px;
  line-height: 18.6px;

  @media (min-width: #{$lg}) {
    font-size: 14px;
    line-height: 21.7px;
  }
}

.TextBlockAlignCenter {
  text-align: center;
}

.TextBlockAlignRight {
  text-align: right;
}

.TextBlockHasRightContent {
  margin-top: 16px;

  @media (min-width: #{$lg}) {
    display: flex;
    align-items: flex-start;
    margin-top: 0;
  }
}

.TextBlockRightContent {
  @media (min-width: #{$lg}) {
    flex: 0 0 auto;
    margin-left: auto;
    padding-left: 20px;
  }
}
