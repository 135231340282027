@import "~@theme/variables.scss";
.newHeaderMenuItem {
  display: block;
  padding: 10px 0;
  color: $slate;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.57143;
  font-weight: 700;
  margin: 0 36px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);

  @media (min-width: $lg) {
    padding: 0;
    margin: 0 0 0 24px;
    color: $slate;
    font-weight: 400;
    border-bottom: none;

    &:hover {
      font-weight: 700;
    }
  }
}
