@import "~@theme/variables.scss";
.FieldContainer {
  margin-bottom: 24px;
}

.FieldContainerNoMargin {
  margin-bottom: 0;
}

.FieldContainerHasError {
  color: $errorRed;
}

.FieldContainerLabel {
  line-height: 1.214;
}

.FieldContainerHelp {
  font-size: 14px;
  margin-top: 5px;
  color: $slate;

  a {
    color: $darkGray;
    font-size: inherit;
    padding: 0;
    min-width: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    vertical-align: inherit;
  }
}

.FieldContainerError {
  color: $errorRed;
  margin: 5px 0;
  font-size: 12px;
}

.helperText {
  font-size: 12px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.helperTextIcon {
  margin-right: 5px;
}
