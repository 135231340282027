@import "~@theme/variables.scss";
.FieldContainerError {
  color: $errorRed;
  margin: 5px 0;
  font-size: 12px;
}

.FieldContainerErrorMedium {
  color: $errorRed;
  margin-bottom: 10px;
}
