@import "~@theme/variables.scss";
.fieldLabel {
  margin-bottom: 8px;
  display: block;
  font-family: $akkuratMono;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5834;
  letter-spacing: 0.16em;

  span {
    font-size: 10px;
    letter-spacing: 0.1em;
  }
}

.accountDialogEditPlanTravelProtectionLabel {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  font-family: $akkuratMono;
  color: $darkestGray;
  margin-bottom: 10px;
}
