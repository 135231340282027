@import "~@theme/variables.scss";
.newHeaderDesktopLoginMenu {
  margin-left: auto;
  display: none;

  @media (min-width: $lg) {
    display: block;
  }
}

.newHeaderLoginItem {
  color: $slate;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
  padding: 10px;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    padding-left: 0;
    color: $darkestGray;

    &:before {
      content: '|';
      padding-right: 10px;
      color: $slate;
      font-weight: 400;
    }
  }
}
