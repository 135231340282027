@import "~@theme/variables.scss";
.completeInformation {
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
}

.completeInformationBlock {
  margin-bottom: 37px;

  @media (min-width: #{$lg}) {
    margin-bottom: 53px;
  }
}

.completeInformationRadios {
  margin-bottom: 12px;
}

.completeInformationMethods {
  padding: 0;
}

.completeInformationMethod {
  margin-bottom: 16px;
  border-radius: 3px;

  &:last-child {
    margin-bottom: 0;
  }
}

.completeInformationMethodContentItem1 {
  color: $darkGray;
  font-size: 14px;
  padding: 0 40px 16px 56px;
  margin-top: -8px;
}

.completeInformationMethodActive {
  background: $lightestGray;
}

.cardElement {
  padding: 16px 14px;
  height: 50px;
  font-family: $akkurat;
  font-weight: 400;
  font-size: 16px;
  border-radius: 2px;
  border: 1px solid;
  border-color: $border;
}

.cardElementNoBorder {
  border-color: transparent;
  background: $white;
}

.completeInformationMethodBtn {
  border-radius: 3px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding: 16px 16px 16px 56px;
  font-size: 15px;
  line-height: 40px;
  text-decoration: none;
  font-weight: 400;

  :global {
    .mark {
      position: absolute;
      left: 16px;
      top: 24px;
    }

    .icon {
      font-size: 24px;
      margin-right: 4px;
    }

    .applePayIcon {
      font-size: 40px;
      width: 63px;
    }

    .googlePayIcon {
      font-size: 40px;
      width: 76px;
    }
  }
}

.completeInformationMethodContent {
  padding: 24px 40px 16px;
}
