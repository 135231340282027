@import "~@theme/variables.scss";
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
}

.rowJustifyStart {
  justify-content: flex-start;
}
