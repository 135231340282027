@import "~@theme/variables.scss";
.PaymentCompleteInformationWrapper {
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;

  a {
    color: $facebookBlue;

    &:hover {
      text-decoration: none;
    }
  }
}
