@import "~@theme/variables.scss";
.accountDialogHeader {
  position: relative;
  background-color: $lightGrayDark;
  padding: 9px 30px;

  @media (min-width: #{$lg}) {
    padding: 17px 50px;
  }
}

.accountDialogHeaderClean {
  background-color: transparent;
  padding: 0 0 15px;
  position: static;
}

.accountDialogHeaderClose {
  position: absolute;
  right: 0;
  top: -50px;
  width: 35px;
  height: 35px;
  background-color: $lightestGray;
  color: $darkGray;

  .icon {
    font-size: 16px;
  }

  &:hover {
    background-color: $slate;
    color: $white;
  }

  @media (min-width: #{$lg}) {
    right: 10px;
    top: 20px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
}

.accountDialogHeaderCloseClean {
  @media (min-width: #{$lg}) {
    right: 11px;
    top: 11px;
  }
}

.accountDialogTitle {
  font-size: 22px;
  line-height: 1.3182;
  letter-spacing: -0.02em;

  @media (min-width: #{$lg}) {
    font-size: 32px;
    line-height: 1.3125;
  }
}

.accountDialogTitleClean {
  text-align: center;
  font-style: normal;
  font-weight: 700;
}
