@import "~@theme/variables.scss";
.col {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.colOneFourthMd {
  @media (min-width: #{$md}) {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

.colOneThirdMd {
  @media (min-width: #{$md}) {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
}

.colOneHalfMd {
  @media (min-width: #{$md}) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.colTwoThirdMd {
  @media (min-width: #{$md}) {
    max-width: 66.666667%;
    flex: 0 0 66.666667%;
  }
}

.colThreeFourthMd {
  @media (min-width: #{$md}) {
    max-width: 75%;
    flex: 0 0 75%;
  }
}

.colOneFourth {
  @media (min-width: #{$lg}) {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

.colOneThird {
  @media (min-width: #{$lg}) {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
}

.colOneHalf {
  @media (min-width: #{$lg}) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.colTwoThird {
  @media (min-width: #{$lg}) {
    max-width: 66.666667%;
    flex: 0 0 66.666667%;
  }
}

.colThreeFourth {
  @media (min-width: #{$lg}) {
    max-width: 75%;
    flex: 0 0 75%;
  }
}

.colOneFourthSm {
  @media (min-width: #{$sm}) {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

.colOneThirdSm {
  @media (min-width: #{$sm}) {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
}

.colOneHalfSm {
  @media (min-width: #{$sm}) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.colTwoThirdSm {
  @media (min-width: #{$sm}) {
    max-width: 66.666667%;
    flex: 0 0 66.666667%;
  }
}

.colThreeFourthSm {
  @media (min-width: #{$sm}) {
    max-width: 75%;
    flex: 0 0 75%;
  }
}
