@import "~@theme/variables.scss";
.newHeaderLogoWrapper {
  padding: 0;
}

.newHeaderLogo {
  display: flex;
  width: 77px;
  height: 40px;
}
